<template>
  <section class="accesos text-center">
    <div class="container" id="formulario">
      <div
        class="row d-flex justify-content-between justify-content-md-around align-items-baseline"
      >
        <div id="url"></div>

        <h2>Consulta Movimientos de Expediente</h2>
        <!-- formulario-->
        <div class="container-fluid">
          <div class="row mb-4">
            <div class="col-lg-12" id="mensaje"></div>
          </div>
          <div class="row mb-4">
            <div class="col-lg-12">
              <div class="alert alert-info" role="alert">
                Por favor complete el formulario a continuación.
              </div>
            </div>
          </div>
          <form id="formcp" @submit.prevent="consultaExpediente">
            <div class="row">
              <div class="form-group col-lg-2 col-12 mb-3">
                <label>Prefijo:</label>
                <input
                  class="form-control"
                  v-model="n_prefijo"
                  type="number"
                  data-parsley-required="true"
                  required
                />
              </div>

              <div class="form-group col-lg-2 col-12 mb-3">
                <label id="dc1">Número:</label>
                <input
                  class="form-control"
                  v-model="numero"
                  type="number"
                  data-parsley-required="true"
                  required
                />
              </div>

              <div class="form-group col-lg-2 col-12 mb-3">
                <label id="dc1">Año:</label>
                <select class="form-select" v-model="anio" required>
                  <option disabled value="">Seleccionar Año</option>
                  <option v-for="year in yearOptions" :key="year">
                    {{ year }}
                  </option>
                </select>
              </div>

              <div class="form-group col-lg-2 col-12 mb-3">
                <label id="dc1">Tipo:</label>
                <select
                  class="form-select"
                  name="select"
                  v-model="tipo"
                  required
                >
                  <option disabled value="">Seleccionar Tipo</option>
                  <option value="EXP">EXP</option>
                  <option value="OC">OC</option>
                  <option value="OFI">OFI</option>
                  <option value="TP">TP</option>
                  <option value="HIST">HIST</option>
                </select>
              </div>

              <div class="form-group col-lg-2 col-12">
                <label id="dc1">Cantidad Movimientos:</label>
                <input
                  class="form-control"
                  v-model="cant_mov"
                  data-parsley-required="true"
                  required
                />
              </div>
              <div class="form-group col-lg-2 col-12 mt-4">
                <button
                  type="submit"
                  class="text-bg-ptn-color-gsj-5 btn btn-primary texto-boton"
                  :disabled="consultado"
                >
                  <span
                    class="material-symbols-outlined fac-span"
                    style="vertical-align: top; margin-top: 3px"
                  >
                    search
                  </span>
                  <span style="vertical-align: top">Buscar</span>
                </button>
              </div>
            </div>
          </form>
        </div>
        <div v-if="first == true">
          <div v-if="tota_items > 1" class="table-responsive">
            <table class="table table-bordered table-striped mt-4">
              <tbody>
                <tr>
                  <td class="w3-uno">JURISDICCIÓN</td>
                  <td class="w3-uno">OFICINA</td>
                  <td class="w3-uno">DESDE</td>
                </tr>
                <tr v-for="(el, index) in items" :key="index">
                  <td class="w3-hover-red w3-text-black">
                    {{ el.title }}
                  </td>
                  <td class="w3-hover-red w3-text-black">
                    {{ el.description }}
                  </td>
                  <td class="w3-hover-red w3-text-black">{{ el.fecha }}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div v-else class="col-lg-12 mt-4">
            <div class="alert alert-danger" role="alert" id="mensajeError">
              No hay expedientes disponibles para la consulta solicitada.
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
// eslint-disable-next-line
/* eslint-disable */
const axios = require("axios");
export default {
  name: "ExpedientesComponent",
  props: {
    titulo: String,
    filtros: Object,
    data: Object,
    resultados: Number,
    showFecha: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      hash: "DLC",
      items: null,
      title: null,
      tota_items: null,
      desc: null,
      current_page: 1,
      results: 5,
      cargando: false,
      nomore: false,
      showMore: true,
      n_prefijo: "",
      numero: "",
      anio: "",
      tipo: "",
      cant_mov: "",
      first: false,
      consultado: false,
    };
  },
  computed: {
    yearOptions() {
      const currentYear = new Date().getFullYear();
      const startYear = 1900;
      const options = [];
      for (let year = currentYear; year >= startYear; year--) {
        options.push(year);
      }
      return options;
    },
  },
  methods: {
    consultaExpediente() {
      this.consultado = true;
      if (this.data == null) {
        let params = {};
        if (this.filtros != null && this.filtros != "") {
          params = this.filtros;
          params.tipo = "DLC";
          params.Prefijo = this.n_prefijo;
          params.Anio = this.anio;
          params.Numero = this.numero;
          params.results = this.cant_mov;
          params.Tipo = this.tipo;
          this.cargando = true;
        }
        axios
          .post(process.env.VUE_APP_API_URL, params)
          .then((response) => {
            this.consultado = false;
            this.first = true;
            this.tota_items = response.data.tota_items;
            this.items = [];
            if (this.items == null) {
              this.items = response.data ? response.data.items : null;
              if (response.data.total_items <= this.results) {
                this.showMore = false;
              }
            } else {
              if (response.data && response.data.items) {
                this.items = response.data.items;
                if (response.data.total_items <= this.results) {
                  this.showMore = false;
                }
              }
            }
            this.title = response.data ? response.data.title : null;
            this.desc = response.data ? response.data.desc : null;
            localStorage.setItem(this.hash, JSON.stringify(response));
            if (this.current_page != 1) {
              this.$refs.showMoreButton.scrollIntoView({
                behavior: "smooth",
                block: "center",
              });
            }
          })
          .catch((error) => {
            if (!error.response) {
              console.log("Fuera de Linea", error);
              if (localStorage.getItem(this.hash)) {
                console.log("Cargando elementos de cache para " + this.hash);
                var resp = JSON.parse(localStorage.getItem(this.hash));
                this.items = resp.data ? resp.data.items : null;
                this.title = resp.data ? resp.data.title : null;
                this.desc = resp.data ? resp.data.desc : null;
                this.nomore = true;
              }
            }
          });
      } else {
        this.items = this.data;
        this.tota_items = this.data;
      }
    },
  },
};
</script>

<style>
.texto-boton {
  font-size: 1.25rem !important;
}
</style>
