<script>
import DocumentListComponent from "./components/resources/DocumentListComponent.vue";
import AutoriryComponent from "./components/resources/AutoriryComponent.vue";
import NewsComponent from "./components/resources/NewsComponent.vue";
import ITCGridComponent from "./components/resources/ITCGridComponent.vue";
import BoxIconGridComponent from "./components/resources/BoxIconGridComponent.vue";
import MultiSliderComponent from "./components/resources/MultiSliderComponent.vue";
import FrecuentAccessComponent from "./components/resources/FrecuentAccessComponent.vue";
import GridLinkComponent from "./components/resources/GridLinkComponent.vue";
import CardGridComponent from "./components/resources/CardGridComponent.vue";
export default {
  name: "HomeView",
  components: {
    /* eslint-disable */
    DocumentListComponent,
    /* eslint-disable */
    AutoriryComponent,
    /* eslint-disable */
    NewsComponent,
    /* eslint-disable */
    ITCGridComponent,
    /* eslint-disable */
    BoxIconGridComponent,
    /* eslint-disable */
    MultiSliderComponent,
    /* eslint-disable */
    FrecuentAccessComponent,
    /* eslint-disable */
    GridLinkComponent,
    /* eslint-disable */
    CardGridComponent,
  },
  data() {
    return {
      site: 9,
    };
  },
  mounted() {
    document.title = process.env.VUE_APP_TITLE;
  },
};
</script>

<template>
  <ITCGridComponent
    :columnas="2"
    titulo="Consultas"
    :data="[
      {
        desc: '',
        icon: 'ptn-i-documento',
        target: null,
        title: 'Consulta de Expedientes',
        url: '?uid=CUMV-VIEW-ExpedientesComponent',
      },
      {
        desc: '',
        icon: 'question_answer',
        target: null,
        title: 'Consultas y solicitudes',
        url: '?uid=CUMV-VIEW-FormComponent',
      },
    ]"
  />

  <NewsComponent titulo="Noticias" />

  <!-- <div class="container">
    <div class="row">
      <div class="col-md-12">
        <DocumentListComponent
          resultados="5"
          :show-fecha="false"
          titulo="Guía de Trámites"
          :filtros="{ site: this.site }"
        />
      </div>
    </div>
  </div> -->

  <CardGridComponent
    columnas="1"
    :data="[
      {
        target: '_self',
        title: 'Licitaciones',
        url: 'https://licitaciones.sanjuan.gob.ar/',
      },
    ]"
  />

 <!--  <BoxIconGridComponent
    titulo="¿Cómo me contacto?"
    :data="[
      {
        title: 'Consultas',
        desc: null,
        icon: 'live_help',
        url: 'https:\/\/deportes.sanjuan.gob.ar\/#dnwvZ29iaWVybm8vc2NyaWFkZXBvcnRlcy9yZWRtaW5lL2NvbnN1bHRhcy9zL19mXzc1NzE0MkFDQzBCOTRERTA4MTk5NUU4MDYxNThBQTI2Lmh0bWx8X3ZhbGlkYWNpb25lc3x8fHw=',
        target: '_self',
      },
      {
        title: 'Reclamos',
        desc: null,
        icon: 'ptn-i-documento',
        url: '#',
        target: '_self',
      },
      {
        title: 'Sugerencias',
        desc: null,
        icon: 'record_voice_over',
        url: 'https:\/\/deportes.sanjuan.gob.ar\/#dnwvZ29iaWVybm8vc2NyaWFkZXBvcnRlcy9yZWRtaW5lL3N1Z2VyZW5jaWFzL3MvX2ZfNzU3MTQyQUNDMEI5NERFMDgxOTk1RTgwNjE1OEFBMjguaHRtbHxfdmFsaWRhY2lvbmVzfHx8fA==',
        target: '_self',
      },
    ]"
  /> -->

  <!-- <AutoriryComponent
    :showProtocolo="false"
    :showLinks="true"
    :data="[
      {
        title: 'Jorge Chica',
        subtitle: 'Secretario de Deportes',
        imagen: require('@/assets/img/secretario-deportes.jpg'),
      },
    ]"
  /> -->
</template>
